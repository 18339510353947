@media (min-width: 1921px) {
    .intro::before {
        background-size: cover;
        width: 100%;
        bottom: -55px;
    }

    .excursion::before {
        background-size: cover;
        width: 100%;
        top: -170px;
    }

    .excursion::after {
        background-size: cover;
        width: 100%;
        bottom: -25px;
    }

    .opportunities::before {
        background-size: cover;
        width: 100%;
    }

    .map-block::before {
        background-size: cover;
        width: 100%;
    }

    .map-block::after {
        background-size: cover;
        width: 100%;
    }

    .map-block {
        background-size: cover;
    }

    .facilities {
        background-size: cover;
    }

    .main-wrap-thx {
        height: 100vh;
    }

    .content-wrap-thx {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .footer-thx {
        &::before {
            content: "";
            position: absolute;
            background-image: url('../img/sky-1.png');
            background-repeat: no-repeat;
            width: 100%;
            height: 432px;
            background-size: cover;
            left: 0;
            top: -420px;
        }
    }

    .intro-thanks {
        height: 90vh;
    }

    .intro-thanks .container {
        align-items: center;
        height: 100%;
        padding-top: 0;
        margin-top: -50px;
    }

    .intro-thanks::before {
        display: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 1800px) {
    .intro-block .parallax-item:nth-child(1) {
        top: 151px !important;
        left: -75px !important;
    }

    .infrastructure .parallax-item:nth-child(1) {
        top: 0px !important;
        left: -185px !important;
    }

    .excursion .parallax-item:nth-child(1) {
        left: -150px !important;
    }

    .building .parallax-item:nth-child(4) {
        right: -142px !important;
    }

    .building .parallax-item:nth-child(2) {
        left: 5% !important;
    }
}

@media (max-width: 1500px) {
    .intro-block .parallax-item:nth-child(1) {
        top: 151px !important;
        left: -130px !important;
    }

    .region__item:nth-child(4) .region__image {
        margin-right: -80px;
    }

    .district__info {
        margin-left: 30px;
        margin-right: -50px;
    }

    .projects .parallax-item:nth-child(1) {
        width: 120px;
    }

    .projects .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .infrastructure .parallax-item:nth-child(1) {
        width: 300px;
        left: -95px !important;
    }

    .infrastructure .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .excursion .parallax-item:nth-child(1) {
        width: 250px;
    }

    .excursion .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .building .parallax-item:nth-child(1) {
        right: 20% !important;
    }

    .building .parallax-item:nth-child(4) {
        width: 220px;
        right: -125px !important;
    }

    .building .parallax-item:nth-child(4) img {
        display: block;
        width: 100%;
    }
}

@media (max-width: 1390px) {
    .building .parallax-item:nth-child(4) {
        display: none !important;
    }

    .building .parallax-item:nth-child(2) {
        width: 90px;
        left: 1% !important;
    }

    .building .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .building .parallax-item:nth-child(3) {
        width: 60px;
    }

    .building .parallax-item:nth-child(3) img {
        display: block;
        width: 100%;
    }

    .payment .main-form {
        margin-right: 0;
    }

    .payment .parallax-wrap--index .parallax-item:nth-child(1) {
        right: 6px !important;
    }

    .building .parallax-item:nth-child(1) {
        right: 13% !important;
    }

    .plan .main-form {
        margin-right: 0;
    }

    .plan .parallax-item:nth-child(3) {
        right: 6px !important;
    }

    .infrastructure .parallax-item:nth-child(1) {
        width: 265px;
    }

    .infrastructure__info {
        margin-right: 0;
    }

    .projects .parallax-item:nth-child(1) {
        display: none !important;
    }

    .selection__description-list {
        right: -33px;
    }

    .excursion .parallax-item:nth-child(1) {
        display: none !important;
    }

    .district__info {
        margin-right: 0;
    }

    .district .list-big {
        margin-left: 30px;
    }

    .region__image img {
        display: block;
        width: 100%;
    }

    .region__item:nth-child(4) .region__image {
        margin-right: -40px;
    }

    .facilities__num-item {
        margin-right: 0;
    }

    .facilities__list {
        margin: 0 auto;
    }

    .presentation .main-form {
        margin-right: 0;
    }

    .presentation .parallax-item:nth-child(2) {
        top: 775px !important;
    }

    .intro__btn-wrap {
        margin-right: 45px;
    }

    .plan .parallax-wrap--index .parallax-item:nth-child(1) {
        width: 120px;
        top: 581px !important;
        right: -40px !important; 
    }

    .plan .parallax-wrap--index .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .presentation .parallax-wrap--index .parallax-item:nth-child(2) {
        width: 120px;
        top: 770px !important;
        right: -40px !important; 
    }

    .presentation .parallax-wrap--index .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .plan .parallax-item:nth-child(1) {
        width: 140px;
        left: 50px !important;
    }

    .plan .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .plan .parallax-item:nth-child(2) {
        width: 100px;
        left: 40px !important;
    }

    .plan .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .payment .parallax-item:nth-child(1) {
        width: 140px;
    }

    .payment .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .payment .parallax-item:nth-child(2) {
        width: 110px;
    }

    .payment .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .payment .parallax-item:nth-child(3) {
        width: 150px;
        left: 37% !important;
    }

    .payment .parallax-item:nth-child(3) img {
        display: block;
        width: 100%;
    }

    .intro::before {
        left: -150px;
    }
}

@media (max-width: 1289px) {
    .selection__main {
        margin: 0;
    }

    .selection__description-list {
        position: static;
        right: 0;
        margin-left: 25px;
    }

    .selection__main-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .selection .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .selection .parallax-item:nth-child(2) {
        top: 282px !important;
        width: 120px;
        right: 100px !important;
    }

    .infrastructure .parallax-item:nth-child(1) {
        display: none !important;
    }

    .infrastructure .container {
        padding-bottom: 60px;
    }

    .opportunities .container {
        padding-top: 60px;
    }

    .materials__image {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .materials__image img {
        display: block;
        width: 100%;
    }

    .presentation .parallax-item:nth-child(3) {
        width: 170px;
    }

    .presentation .parallax-item:nth-child(3) img {
        display: block;
        width: 100%;
    }

    .selection__item {
        padding-left: 30px;
        padding-right: 30px;
    }

}

@media (max-width: 1123px) {
    .facilities__image {
        width: 80%;
        margin: 0 auto;
        min-height: 170px;
    }

    .facilities__item:nth-child(3) .facilities__image {
        width: 55%;
        margin-bottom: -10px;
    }

    .facilities__item:nth-child(3) {
        margin-top: 10px;
    }

    .facilities__image img {
        display: block;
        width: 100%;
    }

    .facilities__num-item p {
        font-size: 76px;
    }

    .intro__title h1 {
        font-size: 45px;
        line-height: 57px;
    }

    .intro__btn-wrap {
        margin-right: 30px;
    }

    .intro::before {
        left: -200px;
    } 

    .presentation__book {
        display: block;
        width: 1000px;
        top: 220px;
    }

    .presentation .container {
        padding-bottom: 160px;
    }

    .facilities__item:nth-child(5) .facilities__image {
        margin-left: 0;
    }

    .presentation .parallax-item:nth-child(2) {
        width: 170px;
        top: 795px !important;
    }

    .presentation .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .facilities .container {
        padding-bottom: 60px;
    }

    .region__image {
        width: 100%;
    }

    .region__item:nth-child(3) .region__image {
        margin-left: 0;
    }

    .region__list {
        margin-top: 0;
    }

    .region__image {
        min-height: 310px;
    }

    .infrastructure__image {
        width: 100%;
    }

    .infrastructure__image img {
        display: block;
        width: 100%;
    }

    .opportunities__item:nth-child(4) .opportunities__num {
        top: 36px;
    }

    .opportunities .container {
        padding-bottom: 485px;
    }

    .materials .container {
        padding-bottom: 90px;
    }

    .plan .parallax-item:nth-child(3) {
        width: 170px;
        right: 45px !important;
    }

    .plan .parallax-item:nth-child(3) img {
        display: block;
        width: 100%;
    }

    .plan__book {
        display: block;
        width: 1100px;
        top: 75px;
    }

    .building .parallax-item:nth-child(1) {
        right: 7% !important;
    }

    .building__docs-list {
        margin-right: 0;
    }

    .building__docs-image {
        margin-left: 0;
    }

    .building__docs-item:nth-child(2) .building__docs-image {
        margin-left: 0;
    }

    .payment .parallax-item:nth-child(1) {
        left: 47% !important;
    }

    .payment__bank-doc {
        left: 35%;
    }

    .payment .parallax-wrap--index .parallax-item:nth-child(1) {
        width: 170px;
    }

    .payment .parallax-wrap--index .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .main-title h2 {
      font-size: 42px;
      line-height: 56px;
    }

    .district .container {
        align-items: center;
    }
}