.parallax-wrap {
  position: absolute !important;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 0;
  top: 0;
}

.intro-block .parallax-item:nth-child(1) {
	top: 100px !important;
	left: -10px !important;
}

.intro-block .parallax-item:nth-child(2) {
	top: 130px !important;
	left: auto !important;
	right: -10px !important;
}

.presentation .parallax-item:nth-child(1) {
  top: 380px !important;
  left: 30% !important;
}

.presentation .parallax-wrap {
  z-index: 2;
}

.presentation .parallax-item:nth-child(2) {
  top: 750px !important;
  left: 55% !important;
}

.presentation .parallax-item:nth-child(3) {
  top: 750px !important;
  left: 10% !important;
}

.presentation .parallax-item:nth-child(4) {
  top: 750px !important;
  left: auto !important;
  right: 0 !important;
}

.presentation .parallax-wrap--index {
  z-index: 0;
}

.presentation .parallax-wrap--index .parallax-item:nth-child(1) {
  top: 450px !important;
  left: 50px !important;
}

.presentation .parallax-wrap--index .parallax-item:nth-child(2) {
  top: 720px !important;
  left: auto !important;
  right: -120px !important;
}

.region .parallax-item:nth-child(1) {
  top: 420px !important;
  left: 40% !important;
}

.region .parallax-item:nth-child(1) img {
  display: block;
  width: 70px;
}

.region .parallax-item:nth-child(2) {
  top: 350px !important;
  left: 62% !important;
}

.projects .parallax-item:nth-child(1) {
  top: 200px !important;
  left: auto !important;
  right: -10px !important;
  width: 203px;
  margin-left: auto;
}

.infrastructure .parallax-item:nth-child(1) {
  top: 0px !important;
  left: -50px !important;
}

.excursion .parallax-wrap {
  z-index: 2;
}

.excursion .parallax-item:nth-child(1) {
  top: 500px !important;
  left: -10px !important;
}

.materials .parallax-item:nth-child(1) {
  top: 220px !important;
  left: 18% !important;
}

.materials .parallax-item:nth-child(2) {
  top: 220px !important;
  left: 42% !important;
}

.materials .parallax-item:nth-child(3) {
  top: 280px !important;
  left: auto !important;
  right: 18% !important;
}

.materials .parallax-item:nth-child(4) {
  top: 210px !important;
  left: auto !important;
  right: 18% !important;
}

.plan .parallax-wrap {
  z-index: 2;
}

.plan .parallax-wrap--index {
  z-index: 1;
}

.plan .parallax-item:nth-child(1) {
  top: 400px !important;
  left: -25px !important;
}

.plan .parallax-item:nth-child(2) {
  top: 240px !important;
  left: -50px !important;
}

.plan .parallax-item:nth-child(3) {
  top: -10px !important;
  left: auto !important;
  right: 0 !important;
}

.plan .parallax-wrap--index .parallax-item:nth-child(1) {
  top: 530px !important;
  left: auto !important;
  right: -150px !important;
  width: 190px;
  margin-left: auto;
}

.building .parallax-item:nth-child(1) {
  top: 150px !important;
  left: auto !important;
  right: 25% !important;
  width: 75px;
  margin-left: auto;
}

.building .parallax-item:nth-child(2) {
  top: 1350px !important;
  left: 12% !important;
}

.building .parallax-item:nth-child(3) {
  top: 1270px !important;
  left: 6% !important;
}

.building .parallax-item:nth-child(4) {
  top: 600px !important;
  left: auto !important;
  right: -10px !important;
}

.payment .parallax-item:nth-child(1) {
  top: -20px !important;
  left: 44% !important;
}

.payment .parallax-item:nth-child(2) {
  top: 20px !important;
  left: 64% !important;
}

.payment .parallax-item:nth-child(3) {
  top: 450px !important;
  left: 30% !important;
}

.payment .parallax-wrap--index {
  z-index: 2;
}

.payment .parallax-wrap--index .parallax-item:nth-child(1) {
  top: 50px !important;
  left: auto !important;
  right: -80px !important;
  width: 215px;
  margin-left: auto;
}

.selection .parallax-item:nth-child(1) {
  top: 240px !important;
  left: -150px !important;
}

.selection .parallax-item:nth-child(2) {
  top: 260px !important;
  left: auto !important;
  right: 0 !important;
}

.selection .parallax-item:nth-child(3) {
  top: 880px !important;
  left: auto !important;
  right: 200px !important;
}