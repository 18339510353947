@import "normalize";
@import "variables";
@import "parallax";
@import "fancybox";
@import "nouislider";



@font-face {
    font-family: 'MADE Evolve Sans';
    src: url('../fonts/subset-MADEEvolveSans-Light.woff2') format('woff2'),
        url('../fonts/subset-MADEEvolveSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Droid Serif';
    src: url('../fonts/subset-DroidSerif-Italic.woff2') format('woff2'),
        url('../fonts/subset-DroidSerif-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Droid Serif';
    src: url('../fonts/subset-DroidSerif.woff2') format('woff2'),
        url('../fonts/subset-DroidSerif.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MADE Evolve Sans';
    src: url('../fonts/subset-MADEEvolveSans.woff2') format('woff2'),
        url('../fonts/subset-MADEEvolveSans.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MADE Evolve Sans';
    src: url('../fonts/subset-MADEEvolveSans-Medium.woff2') format('woff2'),
        url('../fonts/subset-MADEEvolveSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Droid Serif';
    src: url('../fonts/subset-DroidSerif-Bold.woff2') format('woff2'),
        url('../fonts/subset-DroidSerif-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'MADE Evolve Sans';
    src: url('../fonts/subset-MADEEvolveSans-Bold.woff2') format('woff2'),
        url('../fonts/subset-MADEEvolveSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}





.overflowHidden {
  overflow: hidden;
} 

body {
  font-family: 'MADE Evolve Sans', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.container {
  position: relative;
  max-width: 1210px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.main-wrap {
  position: relative;
  overflow: hidden;
}

.intro-block {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.header {
  position: relative;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 26px;
}

.header__logo-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 777px;
  width: 100%;
}

.header__logo-wrap {
  width: 189px;
  margin-right: 40px;
}

.header__logo {
  display: block;
  width: 189px;
}

.header__logo {
  display: block;
  width: 100%;
  margin-bottom: 11px;
}

.header__logo-content span {
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}

.header__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 518px;
  width: 100%;
}

.header__info-item {
  display: flex;
  max-width: 215px;
  width: 100%;
  align-items: center;
}

.header__info-item img {
  margin-right: 17px;
}

.header__item-text p {
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.header__item-text a {
  font-size: 12px;
  color: #ffffff;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border-bottom: 1px dotted #e0e1dd;
  transition: ease 0.3s;

  &:hover {
    border-bottom: 1px dotted #edd183;
    transition: ease 0.3s;
    color: #ffdd84;
  }
}

.header__call-item {
  display: flex;
  max-width: 190px;
  align-items: center;
  width: 100%;
}

.header__call-item img {
  position: relative;
  bottom: -7px;
}

.header__tel-content {
  padding-top: 6px;
}

.header__tel {
  display: block;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 5px;
}

.header__call-btn {
  font-size: 12px;
  color: #fbd165;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px dotted #c2af66;
  transition: ease 0.3s;

  &:hover {
    border-bottom: 1px dotted #edd183;
    transition: ease 0.3s;
    color: #ffdd84;
  }
}

.intro {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/sky-1.png");
    background-repeat: no-repeat;
    width: 1920px;
    height: 432px;
    background-size: cover;
    left: 0;
    bottom: -130px;
  }
}

.intro .container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 259px;
  padding-top: 73px;
}

.intro__content {
  max-width: 745px;
  width: 100%;
  margin-left: 95px;
}

.intro__title h1 {
  font-family: 'Droid Serif', sans-serif;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  font-weight: 400;
  padding-bottom: 3px;
}

.intro__italic {
  color: #fcd771;
  font-style: italic;
}

.intro__title-description {
  font-family: 'MADE Evolve Sans', sans-serif;
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 15px;
}

.intro__decor {
  position: relative;
  padding-left: 32px;

  &::before {
    content: "";
    position: absolute;
    left: 5px;
    top: -2px;
    background-image: url("../img/title-icon.png");
    width: 22px;
    height: 26px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.intro__text {
  font-size: 22px;
  line-height: 56px;
  color: #ffffff;
  padding-bottom: 35px;
}

.intro .btn-main {
  max-width: 375px;
}

.btn-main {
  font-family: 'MADE Evolve Sans', sans-serif;
  position: relative;
  max-width: 315px;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #483f38;
  font-weight: 700;
  transition: ease 0.5s;
  border-radius: 60px;
  box-shadow: 0px 5px 0px 0px rgba(221, 157, 86, 0.004);


  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 99px;
    background-color: #dd9d56;
    border-radius: 60px;
    z-index: 1;
    transition: ease 0.3s;
    box-shadow: 0px 6px 24px 0px rgba(221, 157, 86, 0.6);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 94%;
    height: 79px;
    z-index: -2;
    border-radius: 60px;
  }

  &:hover {
    transform: translate(0px, -3px);
    transition: ease 0.3s;
  }

  &:hover::before {
    background-color: #ecb06d;
    transition: ease 0.3s;
    
  }

  &:active {
    transform: perspective(250px) scale3d(0.99, 0.99, 0.99) translateY(5%);
    transition: ease 0.2s;
    &::before {
      height: 99px;
      transition: ease 0.2s;
    }
  }
}

.btn-main:hover span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient( 90deg, rgb(255,204,71) 0%, rgb(255,229,150) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(255,204,71) 0%, rgb(255,229,150) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(255,204,71) 0%, rgb(255,229,150) 100%);
  opacity: 1;
  border-radius: 60px;
  z-index: -1;
  animation: gradient 1s linear infinite alternate;
  animation-delay: 0.5s;
}

.btn-main span {
  display: inline-block;
  position: relative;
  width: 100%;
  background-image: -moz-linear-gradient( 90deg, rgb(245,186,51) 0%, rgb(255,225,135) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(245,186,51) 0%, rgb(255,225,135) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(245,186,51) 0%, rgb(255,225,135) 100%);
  line-height: 89px;
  border-radius: 60px;
  padding-top: 5px;
  z-index: 2;
  will-change: transform;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient( 90deg, rgb(255,204,71) 0%, rgb(255,229,150) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(255,204,71) 0%, rgb(255,229,150) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(255,204,71) 0%, rgb(255,229,150) 100%);
    transition: ease 0.3s;
    opacity: 0;
    border-radius: 60px;
    z-index: -1;
  }
}

.intro__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  background-image: -moz-linear-gradient( 90deg, rgb(135,178,44) 0%, rgb(164,210,50) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(135,178,44) 0%, rgb(164,210,50) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(135,178,44) 0%, rgb(164,210,50) 100%);
  transition: ease 0.3s;
  z-index: 1;
  margin-bottom: 44px;
  margin-left: auto;

  &:hover::before {
    transition: ease 0.3s;
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: -moz-linear-gradient( -90deg, rgb(135,178,44) 0%, rgb(164,210,50) 100%);
    background-image: -webkit-linear-gradient( -90deg, rgb(135,178,44) 0%, rgb(164,210,50) 100%);
    background-image: -ms-linear-gradient( -90deg, rgb(135,178,44) 0%, rgb(164,210,50) 100%);
    opacity: 0;
    transition: ease 0.3s;
    border-radius: 50%;
    z-index: -1;
  }
}

.intro__btn img {
  display: block;
  margin-right: -5px;
}

.intro__btn-wrap p {
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  width: 130px;
}

.intro__btn-wrap {
  position: relative;
  margin-right: 77px;
  margin-bottom: -20px;
}

.presentation {
  position: relative;
}

.main-title {
  position: relative;
  z-index: 2;
}

.main-title h2 {
  font-family: 'Droid Serif', sans-serif;
  font-size: 44px;
  line-height: 58px;
  color: #000000;
  font-weight: 400;
}

.main-title h2 span {
  color: #8bb729;
  font-style: italic;
}

.main-title p {
  font-size: 22px;
  color: #828b9a;
  padding-top: 16px;
}

.presentation .main-title {
  max-width: 520px;
  width: 100%;
}

.presentation .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 225px;
}

.main-form {
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
  max-width: 423px;
  width: 100%;
  padding: 8px;
}

.main-form__wrap {
  border-radius: 12px;
  border: 1px solid #f3f7fa;
  padding-top: 45px;
  padding-bottom: 40px;
}

.main-form__title {
  font-family: 'Droid Serif', sans-serif;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  font-weight: 400;
  padding-bottom: 8px;
}

.main-form__title span {
  font-style: italic;
}

.main-form__subtitle {
  font-size: 18px;
  line-height: 26px;
  color: #828b9a;
}

.main-form form {
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
}

.main-form__field input {
  width: 100%;
  background-color: #f8f9fa;
  font-size: 18px;
  color: #000000;
  padding-left: 37px;
  padding-right: 37px;
  height: 72px;
  box-shadow: inset 0px 1px 3px 0px rgba(205, 215, 225, 0.14);
  transition: ease 0.3s;
  border: 1px solid transparent;
  border-radius: 4px;

  &::placeholder {
    color: #000;
    opacity: 1;
  }

  &:focus {
    transition: ease 0.3s;
    border: 1px solid #ff9db2;
  }
}

.main-form__field {
  margin-bottom: 12px;

  &:last-of-type {
    padding-bottom: 8px;
  }
}


.main-form__small-text {
  display: block;
  font-size: 13px;
  line-height: 22px;
  color: #828b9a;
  text-align: center;
  padding-bottom: 20px;
}

.main-form__title-content {
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 26px;
}

.main-form__description {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  text-align: center;
}

.main-form__description a {
  color: #f7c244;
  transition: ease 0.3s;

  &:hover {
    transition: ease 0.3s;
    color: #f3b934;
  }
}

.main-form .btn-main {
  margin-bottom: 20px;
}

.presentation .main-form {
  position: relative;
  z-index: 2;
  margin-right: -55px;
  margin-top: 135px;
}

.presentation__book {
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 1;
}

.facilities {
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom;
}

.facilities .container {
  padding-bottom: 90px;
}

.facilities__title-content {
  display: flex;
  justify-content: flex-end;
}

.facilities .main-title {
  text-align: center;
  max-width: 775px;
  width: 100%;
  margin-right: 95px;
}

.facilities__num-item {
  width: 150px;
  margin-right: -45px;
  margin-top: -30px;
}

.facilities__num-item p {
  display: flex;
  font-family: 'Droid Serif', sans-serif;
  font-size: 100px;
  color: #bdcc6a;
  font-weight: 700;
  align-items: baseline;
}

.facilities__num-item p span {
  display: inline-block;
  font-weight: 400;
  font-size: 24px;
  margin-left: 8px;
}

.facilities__num-text {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.facilities__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -30px;
  margin-right: -30px;
  padding-top: 42px;
  padding-bottom: 292px;
}

.facilities__item {
  max-width: 190px;
  width: 100%;
  text-align: center;

  &:nth-child(2) {
    margin-top: 60px;
  }

  &:nth-child(4) {
    margin-top: 20px;
  }

  &:nth-child(5) {
    margin-top: 40px;
  }
}

.facilities__item:nth-child(1) .facilities__image {
  margin-left: 50px;
}

.facilities__item:nth-child(2) .facilities__image {
  margin-bottom: -40px;
}

.facilities__item:nth-child(5) .facilities__image {
  margin-bottom: -20px;
  margin-left: -30px;
}

.facilities__item strong {
  display: block;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 17px;
}

.facilities__item p {
  font-size: 16px;
  line-height: 24px;
  color: #828b9a;
}

.facilities__image {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 197px;
}

.facilities__description {
  max-width: 838px;
  width: 100%;
  margin: 0 auto;
  padding: 9px;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
}

.facilities__description-wrap {
  border: 1px solid #f3f7fa;
  border-radius: 12px;
}

.facilities__description-wrap p {
  font-family: 'Droid Serif', sans-serif;
  text-align: center;
  font-size: 26px;
  line-height: 38px;
  color: #828b9a;
  font-style: italic;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 48px;
  padding-bottom: 60px;
}

.region {
  position: relative;
}

.region .main-title {
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.region .container {
  max-width: 1250px;
  padding-top: 13px;
}

.region__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -150px;
}

.region__item {
  max-width: 250px;
  width: 100%;
  text-align: center;

  &:nth-child(2) {
    margin-top: 135px;
  }

  &:nth-child(3) {
    margin-top: -25px;
  }

  &:nth-child(4) {
    margin-top: 105px;
    max-width: 260px;
  }
}

.region__item:nth-child(3) .region__image {
  margin-bottom: 25px;
  margin-left: -120px;
  
}

.region__item:nth-child(4) .region__image {
  margin-right: -170px;
}

.region__image {
  min-height: 509px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.region__item strong {
  display: block;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 12px;
}

.region__item p {
  font-size: 16px;
  line-height: 24px;
  color: #828b9a;
}

.district {
  position: relative;
}

.district .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
}

.district__content {
  max-width: 480px;
  width: 100%;
  padding-top: 125px;
}

.district .list-big {
  margin-left: 68px;
  padding-top: 80px;
}

.list-big li {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  padding-left: 68px;
  margin-bottom: 24px;
  color: #000000;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid #000;
    border-radius: 50%;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    background-image: url("../img/checked-b.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    left: 14px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.district__info {
  position: relative;
  max-width: 749px;
  width: 100%;
  margin-right: -110px;
}

.district__info img {
  display: block;
  width: 100%;
}

.district__link {
  position: absolute;
  left: 140px;
  width: 180px;
  top: 50%;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.08em;
  transition: ease 0.3s;
  text-decoration: underline;
  transform: translate(0, -50%);

  &:hover {
    color: #ffdd84;
    text-decoration-color: #ffdd84;
    transition: ease 0.3s;
  }

  &::before {
    content: "";
    position: absolute;
    top: -51px;
    left: -14px;
    background-image: url("../img/mouse-icon.png");
    width: 32px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}

.projects {
  position: relative;
}

.projects .container {
  padding-bottom: 130px;
} 

.projects .main-title {
  text-align: center;
  max-width: 835px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 61px;
}

.projects__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -119px;
}

.projects .list-green {
  max-width: 330px;
  width: 100%;
}

.list-green li {
  padding-left: 54px;
  margin-bottom: 20px;

  &::before {
    display: none;
  }

  &::after {
    width: 31px;
    height: 31px;
    background-image: url("../img/checked-g.png");
    top: 11px;
    left: 0;
  }
}

.projects__image {
  display: block;
  max-width: 880px;
  width: 100%;
  margin-right: 45px;
}

.projects__image img {
  display: block;
  width: 100%;
}

.infrastructure {
  position: relative;
  z-index: 3;
}

.infrastructure .container {
  padding-bottom: 126px;
}

.infrastructure__title-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.infrastructure .main-title {
  text-align: center;
  max-width: 705px;
  width: 100%;
  margin: 0 auto;
}

.infrastructure__info {
  max-width: 198px;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
  padding-top: 12px;
  padding-bottom: 36px;
  padding-left: 35px;
  padding-right: 35px;
  margin-right: -62px;
}

.infrastructure__info img {
  margin-bottom: 7px;
}

.infrastructure__info p {
  font-size: 17px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 21px;

  &:last-child {
    padding-bottom: 0;
  }
}

.infrastructure__list {
  max-width: 1085px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 60px;
}

.infrastructure__item {
  max-width: 205px;
  width: 100%;
  text-align: center;
}

.infrastructure__item strong {
  display: block;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 14px;
}

.infrastructure__item p {
  font-size: 16px;
  line-height: 24px;
  color: #828b9a;
}

.infrastructure__image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 165px;
}

.excursion {
  position: relative;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/sky-4.png");
    width: 1920px;
    background-size: cover;
    height: 432px;
    background-repeat: no-repeat;
    top: -100px;
    left: 0;

  }

  &::after {
    content: "";
    position: absolute;
    background-image: url("../img/sky-3.png");
    width: 1920px;
    background-size: cover;
    height: 393px;
    background-repeat: no-repeat;
    bottom: -100px;
    left: 0;
    z-index: 0;
    pointer-events: none;

  }
}

.excursion .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 157px;
  padding-bottom: 133px;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/sky-5.png");
    width: 818px;
    background-size: cover;
    height: 806px;
    background-repeat: no-repeat;
    bottom: -100px;
    left: -350px;
    z-index: -1;
    pointer-events: none;

  }
}

.excursion__content {
  max-width: 423px;
  width: 100%;
}

.excursion .main-title {
  padding-bottom: 76px;
}

.excursion .main-title p {
  padding-top: 35px;
  line-height: 32px;
}

.list-title {
  display: block;
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 37px;
  color: #000000;
  font-weight: 500;
}

.excursion .list-title {
  margin-left: 25px;
}

.excursion .list-green {
  margin-left: 25px;
  max-width: 292px;
  width: 100%;
}

.main-form--space .btn-main {
  margin-top: 7px;
}

.excursion .main-form {
  margin-top: 36px;
}

.opportunities {
  position: relative;
  background-position: bottom;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/sky-1.png");
    width: 1916px;
    background-size: cover;
    height: 432px;
    background-repeat: no-repeat;
    bottom: -30px;
    left: 0;
    pointer-events: none;
  }
}


.opportunities .main-title {
  text-align: center;
  max-width: 825px;
  width: 100%;
  margin: 0 auto;
}

.opportunities .container {
  padding-top: 121px;
  padding-bottom: 532px;
}

.opportunities__list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 32px;
}

.opportunities__item {
  position: relative;
  text-align: center;
  width: 33%;
}

.opportunities__item strong {
  display: block;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  max-width: 225px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 15px;
}

.opportunities__item p {
  font-size: 16px;
  line-height: 24px;
  color: #828b9a;
  max-width: 225px;
  width: 100%;
  margin: 0 auto;
}

.opportunities__image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 280px;
  z-index: 1;
}

.opportunities__image img {
  margin-bottom: 5px;
}

.opportunities__num {
  font-family: 'Droid Serif', sans-serif;
  position: absolute;
  font-size: 86px;
  color: #f5f6f9;
  font-weight: 700;
}

.opportunities__item:nth-child(1) .opportunities__num {
  top: 130px;
  right: 27px;
}

.opportunities__item:nth-child(2) .opportunities__num {
  top: 100px;
  right: 60px;
}

.opportunities__item:nth-child(3) .opportunities__num {
  top: 45px;
  right: 80px;
}

.opportunities__item:nth-child(4) .opportunities__num {
  top: 70px;
  right: 65px;
}

.opportunities__item:nth-child(5) .opportunities__num {
  top: 105px;
  right: 80px;
}

.materials {
  position: relative;
  margin-top: -20px;
}

.materials .container {
  padding-bottom: 125px;
}

.materials .main-title {
  text-align: center;
  max-width: 645px;
  margin: 0 auto;
}

.materials__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1080px;
  width: 100%;
  padding-top: 75px;
  margin: 0 auto;
}

.materials__item {
  max-width: 276px;
  width: 100%;
  text-align: center;
}

.materials__item strong {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  padding-bottom: 15px;
  font-weight: 500;
}

.materials__item p {
  font-size: 18px;
  line-height: 26px;
  color: #828b9a;
}

.materials__image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 169px;
  margin-bottom: 30px;
}

.plan {
  position: relative;
  border-bottom: 1px solid rgb(235, 240, 243);
}

.plan .container {
  padding-bottom: 80px;
}

.plan__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.plan .main-title {
  max-width: 540px;
  width: 100%;
}

.plan .main-form {
  position: relative;
  z-index: 2;
  margin-top: 50px;
  margin-right: -55px;
}

.plan__book {
  position: absolute;
  z-index: 0;
  left: -190px;
  top: 30px;
}

.plan__list {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  padding-top: 49px;
}

.plan__item {
  margin-right: 59px;

  &:last-child {
    margin-right: 0;
  }
}

.plan__item p {
  padding-top: 7px;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}

.building {
  position: relative;
}

.building .container {
  padding-top: 100px;
  padding-bottom: 172px;
}

.building .main-title {
  text-align: center;
}

.building__list {
  display: flex;
  justify-content: space-between;
  padding-top: 55px;
}

.building__item {
  max-width: 350px;
  width: 100%;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
  text-align: center;
  padding: 9px;
}

.building__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border: 1px solid #f3f7fa;
  border-radius: 12px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 45px;
}

.building__strong {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  padding-top: 57px;
}

.building__city {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  color: #8c94a2;
  letter-spacing: 0.2em;
  font-weight: 500;
  padding-bottom: 14px;
}

.building__item {
  font-size: 16px;
  color: #000000;
  line-height: 24px;
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}


.building__link {
  font-size: 16px;
  color: #348cd8;
  transition: ease 0.3s;

  &:hover {
    transition: ease 0.3s;
    opacity: 0.7;
  }
}

.building__text-content {
  padding-bottom: 17px;
}

.building__text-content strong {
  display: block;
  padding-bottom: 7px;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.building__image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 227px;
  margin-bottom: 27px;
}

.main-form--small .main-form__title {
  font-size: 28px;
  font-style: italic;
}

.main-form--small {
  padding: 0;
  box-shadow: none;
  max-width: 315px;
  background-color: transparent;
}

.main-form--small .main-form__wrap {
  border: none;
  background-color: transparent;
  padding: 0;
}

.main-form--small .main-form__title span {
  color: #8bb729;
}

.main-form--small .main-form__field input::placeholder {
  color: #828b9a;
}

.building__docs-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 110px;
  padding-top: 132px;
}

.building__docs-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 510px;
  width: 100%;
  margin-right: 70px;
}

.building__docs-item {
  max-width: 249px;
  width: 100%;
}

.building__docs-item strong {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  font-weight: 500;
  padding-bottom: 22px;
}

.building__docs-item p {
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.building__docs-item:nth-child(2) {
  max-width: 195px;
}

.building__docs-item:nth-child(2) .building__docs-image {
  margin-left: -80px;
}

.building__docs-image {
  margin-bottom: 20px;
  margin-left: -60px;
}

.payment {
  position: relative;
  z-index: 2;
}

.payment .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.payment__content {
  max-width: 440px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.payment__content-info {
  max-width: 275px;
  width: 100%;
  margin-left: 50px;
  padding-top: 59px;
}

.payment__price-info {
  padding-top: 85px;
}

.payment__price-info strong {
  position: relative;
  display: block;
  font-size: 28px;
  padding-bottom: 20px;
  color: #000000;
  font-weight: 500;
  padding-left: 53px;

  &::before {
    content: '';
    position: absolute;
    background-image: url("../img/rubs.png");
    width: 44px;
    height: 28px;
    left: -7px;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.payment__price-info p {
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  padding-left: 53px;
}

.payment .main-form {
  position: relative;
  z-index: 1;
  margin-top: 105px;
  margin-right: -55px;
}

.payment__bank-doc {
  position: absolute;
  top: 100px;
  left: 32%;
  z-index: 0;
}

.map-block {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    background-image: url("../img/sky-map-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 432px;
    width: 1920px;
    left: 0;
    top: -2px;
  }

  &::after {
    content: '';
    position: absolute;
    background-image: url("../img/sky-map-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 432px;
    width: 1920px;
    left: 0;
    bottom: -2px;
  }
}

.map-block .container {
  max-width: 1350px;
  padding-top: 420px;
}

.map-block__content {
  position: relative;
  width: 100%;
  height: 660px;
  z-index: 2;
  border-radius: 12px;
}

#map {
  width: 100%;
  height: 660px;
  border-radius: 12px;
}

.map-block__info {
  position: absolute;
  top: 50%;
  left: 110px;
  transform: translate(0, -50%);
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
  max-width: 478px;
  width: 100%;
  padding-left: 59px;
  padding-right: 52px;
  padding-top: 55px;
  padding-bottom: 37px;
  z-index: 2;
}

.map-block__info h4 {
  font-family: 'Droid Serif', sans-serif;
  font-size: 36px;
  color: #000000;
  font-weight: 400;
}

.map-block__info h4 span {
  font-style: italic;
  color: #8bb729;
}

.map-block__description {
  font-size: 18px;
  color: #828b9a;
  padding-top: 11px;
}

.map-block__contacts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 45px;
}

.map-block__contacts-item {
  max-width: 162px;
  width: 100%;
  padding-bottom: 25px;
}

.map-block__contacts-item strong {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  color: #828b9a;
  font-weight: 500;
  padding-bottom: 10px;
  letter-spacing: 0.2em;
}

.map-block__contacts-item p {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.map-block__tel {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.map-block__email {
  font-size: 16px;
  color: #000;
}

.footer {
  position: relative;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 43px;
  padding-bottom: 43px;
}

.footer__logo-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 320px;
  width: 100%;
  margin-right: 80px;
}

.footer__logo {
  display: inline-block;
  width: 146px;
}

.footer__logo-content span {
  display: block;
  width: 142px;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.footer__logo img {
  display: block;
  width: 100%;
}

.footer__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 725px;
  width: 100%;
}

.footer__links a {
  font-size: 14px;
  line-height: 26px;
  color: #8c94a2;
  transition: ease 0.3s;

  &:hover {
    color: #9599a2;
    transition: ease 0.3s;
  }
}

.footer__social-content {
  display: flex;
}

.footer__social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: rgb(189, 204, 106);
  transition: ease 0.3s;
  margin-right: 9px;

  &:hover {
    background-color: #b0c24d;
    transition: ease 0.3s;
  }

  &:last-child {
    margin-right: 0;
  }
}

.footer__tel {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 4px;
}

.footer__mail {
  font-size: 13px;
  color: #000000;
}

.footer__tel-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selection {
  position: relative;
}

.selection .main-title {
  text-align: center;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 64px;
}

.selection .container {
  padding-top: 67px;
  padding-bottom: 109px;
}

.selection__main {
  position: relative;
  z-index: 2;
  max-width: 1003px;
  width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
  padding: 9px;
}

.selection__wrap {
  border: 1px solid #f3f7fa;
  padding-top: 65px;
  padding-bottom: 75px;
}

.selection__questions {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  border-radius: 60px;
  background-color: #fafafa;
  line-height: 40px;
  margin-bottom: 47px;
  text-align: center;
  &.step-1 {
    .selection__question-bar {
      width: 33%;
    }
  }
  &.step-2 {
    .selection__question-bar {
      width: 66%;
    }
    span {
      color: #fff;
    }
  }
  &.step-3 {
    .selection__question-bar {
      width: 100%;
    }
    span {
      color: #fff;
    }
  }
}

.selection__questions span {
  position: relative;
  font-size: 16px;
  color: #828b9a;
  z-index: 1;
}

.selection__question-bar {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 60px;
  height: 40px;
  background-image: -moz-linear-gradient( 90deg, rgb(251,46,78) 0%, rgb(255,63,94) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(251,46,78) 0%, rgb(255,63,94) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(251,46,78) 0%, rgb(255,63,94) 100%);
  z-index: 0;
  transition: width 0.5s ease-in-out;
  &::before {
    content: '';
    position: absolute;
    height: 40px;
    border-radius: 60px;
    width: 100%;
    left: 0;
    background: repeating-linear-gradient(-45deg,rgba(255,255,255,.09),rgba(255,255,255,.09) 5px,transparent 0px,transparent 10px) fixed;
  }
}

.selection__inner {
  &.final {
    display: none;
  }
}
.selection__item-title-wrap {
  display: block;
  font-size: 32px;
  color: #000;
  font-weight: 500;
  margin-bottom: 58px;
}
.selection__item-title {
  display: none;
  &.step-1 {
    display: block;
  }
}

/*TODO: remove form title*/
.selection__form-title {
  display: block;
  font-size: 32px;
  color: #000;
  font-weight: 500;
  margin-bottom: 58px;
}


.selection__step {
  display: none;
  &.step-1 {
    display: block;
  }
}
.jsSelectionNext {
  cursor: pointer;
}
.jsSelectionPrev {
  display: none;
  cursor: pointer;
}



.selection__item,
.selection__inner {
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
}

.selection__select-price {
  font-size: 48px;
  color: #000000;
  font-weight: 300;
}

.selection__price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.selection__pricer {
  position: relative;
  font-size: 18px;
  color: #000;
}

.selection__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 550px;
  width: 100%;
  padding-top: 45px;
}

.selection__prev-btn {
  background-color: transparent;
  transition: ease 0.3s;
  font-size: 13px;
  color: #b1b6c0;
  letter-spacing: 0.08em;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    transition: ease 0.3s;
    color: #9599a2;
  }
}

.selection__price-container .rub {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 7px;
    left: 0px;
    bottom: 7px;
    height: 1px;
    background-color: #000;
  }
}

.selection__select-price-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
}

.selection__select-price-item .rub {
  position: relative;
  font-size: 48px;
  font-weight: 300;
  margin-left: 17px;

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    left: -1px;
    bottom: 19px;
    height: 3px;
    background-color: #000;
  }
}

.selection__description-list {
  position: absolute;
  top: 540px;
  max-width: 115px;
  width: 100%;
  right: -85px;
}

.selection__description-item {
  padding-bottom: 17px;
}

.selection__description-item strong {
  font-family: 'Droid Serif', sans-serif;
  font-size: 70px;
  color: #bdcc6a;
}

.selection__description-item p {
  font-size: 18px;
  line-height: 26px;
  color: #000;
  margin-top: -3px;
}

.question-2 .selection__questions span {
  color: #fff;
}

.question-3 .selection__questions span {
  color: #fff;
}

.question-2, .question-3 {
  display: none;
}

.toggle-ingo {
  font-size: 48px;
  color: #000000;
  font-weight: 300;
  margin-left: 10px;
}

.popup {
  display: none;
  max-width: 425px;
  width: 100%;
}

.popup .main-form__title-content {
  max-width: 330px;
}

.popup-big {
  display: none;
  max-width: 910px;
  width: 100%;
}

.popup-big .main-form__wrap {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.popup-big__main {
  background-color: #fff;
  max-width: 420px;
  width: 100%;
  padding-top: 65px;
  padding-bottom: 60px;
}

.popup-big__bg {
  max-width: 480px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-left: -9px;
  margin-bottom: -9px;
  margin-top: -9px;
}

.fancybox-slide--html .fancybox-close-small {
    color: #fff;
    right: -38px;
    width: 34px;
    height: 34px;
    padding: 0;
    top: -8px;
    transition: ease 0.3s;
    opacity: 1;
}

.fancybox-button svg {
    width: 34px;
    height: 34px;
}

.fancybox-content {
    overflow: initial;
}

.fancybox-close-small:hover {
    opacity: 0.7;
    transition: ease 0.3s;
}

.popup__leaf {
  display: block;
  width: 160px;
  position: absolute;
  top: -27px;
  right: 27px;
  z-index: 2;
}

.popup__berry {
  display: block;
  width: 150px;
  position: absolute;
  bottom: 30px;
  right: -80px;
  z-index: -1;
}

.list-green__info-item {
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
  border-bottom: 1px dotted #bfbfbf;
  transition: ease 0.3s;
}

.projects__duplex-content {
  position: absolute;
  max-width: 336px;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 14px 59px 0px rgba(163, 169, 183, 0.15);
  padding: 8px;
  transition: ease 0.3s;
  pointer-events: none;
  opacity: 0;
  top: 175px;
  right: 150px;
  z-index: 2;
}

.projects__duplex-content.active {
   opacity: 1;
   transition: ease 0.3s;
}

.projects__duplex-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f3f7fa;
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 27px;
}


.projects__duplex-wrap p {
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  max-width: 165px;
}

.projects__duplex-wrap img {
  margin-right: 24px;
}

.presentation .main-title {
  margin-right: 30px;
}

.infrastructure__info--mobile {
  display: none;
}

.map-block__info--mobile {
  display: none;
  transform: translate(0, 0);
  margin: 0 auto;
  box-shadow: none;
}

.header__tel--mobile {
  display: none;
}

.intro-thanks .intro__title h2 {
  font-family: 'Droid Serif', sans-serif;
  font-size: 44px;
  padding-bottom: 3px;
  font-weight: 400;
  color: #fff;
}

.intro-thanks .intro__title span {
  font-style: italic;
  color: #fcd771;
}

.intro-thanks__info p {
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.intro-thanks__info a {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
}

.intro-thanks__time-content {
  padding-bottom: 51px;
}

.intro-thanks__tel-content {
  padding-bottom: 30px;
}

.intro-thanks__tel-content p {
  padding-bottom: 5px;
}

.circle-pulse  {
  position: absolute;
  border-radius: 50%;
  top: 50px;
  left: 78px;
  animation: pulse 3s infinite ease-out;
  transform: translate(-50%, -50%);
}

.circle-1 {
  width: 120px;
  height: 120px;
  background-color: transparent;
  border: 1px solid #a2cb39;
  border-radius: 50%;
}

.circle-2 {
  width: 110px;
  height: 110px;
  background-color: transparent;
  border: 1px solid #a2cb39;
  border-radius: 50%;
  -webkit-animation-delay: 0.333s;
          animation-delay: 0.333s;
}

.circle-3 {
  width: 90px;
  height: 90px;
  background-color: transparent;
  border: 1px solid #a2cb39;
  border-radius: 50%;
  -webkit-animation-delay: 0.667s;
          animation-delay: 0.667s;
}

.circle-4 {
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  background-color: transparent;
  border: 1px solid #a2cb39;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0);
            transform: translate(-50%, -50%) scale(0) rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(2) rotate(360deg);
            transform: translate(-50%, -50%) scale(2) rotate(360deg);
    opacity: 0;
  }
}

.main-form label.error {
  display: block;
  font-size: 14px;
  color: red;
  padding-left: 40px; 
}

.selection__inner.final label.error {
  display: block;
  font-size: 14px;
  color: red;
  padding-left: 40px; 
}

.selection__inner.final .main-form__title-content {
  max-width: none;
}

.selection__inner.final .main-form__title {
  font-size: 38px;
}

.selection__inner.final .main-form__title span{
  color: #8bb729;
} 

.selection__inner.final .main-form__subtitle {
  font-size: 22px;
  padding-top: 13px;
}

.selection__inner.final .main-form__field {
  max-width: 565px;
}

.selection__inner.final .btn-main {
  max-width: 565px;
}

.selection__inner.final .btn-main i {
  font-style: normal;
  max-width: 565px;
}

.selection__inner.final .main-form__description {
  max-width: 565px;
  width: 100%;
  margin-top: 25px;
  text-align: center;
}

.selection__inner.final .main-form__description br {
  display: none;
}

#video-bg {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  min-width: 100%; 
  min-height: 100%;
}

.content-wrap {
  position: relative;
  background-color: #fff;
}

.intro-block__video {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  min-width: 100%; 
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    left: 0;  
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.noUi-value {
  display: none;
}

.noUi-marker-horizontal.noUi-marker-large {
  display: none;
}

@import "media-desktop";
@import "media-mobile";