html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
}

button,
input {
  padding: 0;
  border: none;
  outline: none; 
}

button {
  cursor: pointer;
}




input,
textarea,
button,
select,
label,
div,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
































