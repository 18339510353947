@media (max-width: 1023px) {
    .intro-block .parallax-wrap {
        display: none;
    }

    .header__list {
        max-width: 440px;
    }

    .header__logo-content {
        max-width: 660px;
        justify-content: flex-start;
    }

    .header__logo-wrap {
        width: 145px;
        margin-right: 60px;
    }

    .intro__title-description {
        display: block;
        margin-top: 5px;
    }

    .main-form {
        max-width: 385px;
    }

    .presentation .parallax-wrap {
        display: none;
    }

    .presentation .main-form {
        margin-top: 0;
    }

    .presentation__book {
        top: 120px;
    }

    .facilities .main-title {
        text-align: left;
    }

    .facilities__item {
        width: 19%;
        max-width: none;
    }

    .facilities__item:nth-child(1) .facilities__image {
        margin: 0 auto;
    }

    .region__item {
        max-width: none;
        width: 22%;
    }

    .region__item:nth-child(2) {
        margin-top: 0;
    }

    .region__item:nth-child(4) {
        margin-top: 0;
    }

    .region .parallax-wrap {
        display: none;
    }

    .region__image {
        min-height: 270px;
    }

    .selection .parallax-wrap {
        display: none;
    }

    .selection .main-title {
        padding-bottom: 40px;
    }

    .district__link {
        left: 105px;
        top: 55%;
    }

    .district .list-big {
        padding-top: 50px;
    }

    .intro__title h1 {
        font-size: 40px;
        line-height: 52px;
    }

    .intro__text {
        font-size: 20px;
    }

    .presentation .container {
        padding-top: 80px;
    }

    .selection__main-container {
        flex-direction: column;
        align-items: center;
    }

    .selection__description-list {
        margin-left: 0;
        display: flex;
        max-width: 280px;
        justify-content: space-between;
        padding-top: 30px;
        margin-right: -55px;
    }

    .selection .container {
        padding-bottom: 63px;
    }

    .projects__duplex-content {
        max-width: 300px;
    }

    .projects__duplex-wrap {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .projects__duplex-wrap p {
        font-size: 16px;
        line-height: 22px;
    }

    .projects .container {
        padding-bottom: 90px;
    }

    .infrastructure__info {
        display: none;
    }

    .infrastructure__info--mobile {
        display: flex;
        max-width: 328px;
        align-items: flex-start;
        margin: 0 auto;
        margin-top: 55px;
    }

    .infrastructure__info--mobile img {
      margin-right: 17px;
      margin-bottom: 0;
    }

    .infrastructure .container {
        padding-bottom: 20px;
    }

    .excursion .main-title p {
        text-shadow: 0 0 30px #fff, 0 0 30px #fff, 0 0 30px #fff, 0 0 30px #fff, 0 0 30px #fff, 0 0 30px #fff,0 0 30px #fff, 0 0 30px #fff, 0 0 30px #fff;
    }

    .opportunities::before {
        bottom: -130px;
        left: -370px;
    }

    .opportunities {
        background-size: contain;
    }

    .opportunities .container {
        padding-bottom: 300px;
    }

    .materials {
        margin-top: 0;
    }

    .materials .container {
        padding-top: 40px;
    }

    .materials .parallax-item:nth-child(1) {
        position: absolute;
        top: 270px !important;
    }

    .materials .parallax-item:nth-child(2) {
        position: absolute;
        top: 270px !important;
    }

    .materials .parallax-item:nth-child(3) {
        position: absolute;
        top: 300px !important;
    }
    
    .materials .parallax-item:nth-child(4) {
        position: absolute;
        top: 225px !important;
    }

    .materials__list {
        padding-top: 50px;
    }

    .plan .main-title {
        position: relative;
        z-index: 3;
    }

    .plan__book {
        width: 1000px;
        left: -120px;
    }

    .plan .parallax-item:nth-child(1) {
        position: absolute;
        width: 75px;
    }

    .plan .parallax-item:nth-child(2) {
        position: absolute;
        width: 70px;
    }

    .plan .parallax-item:nth-child(3) {
        display: none;
    }

    .plan .parallax-wrap--index .parallax-item:nth-child(1) {
        display: none;
    }

    .materials .container {
        padding-bottom: 70px;
    }

    .building .parallax-wrap {
        display: none;
    }

    .plan .container {
        padding-bottom: 40px;
    }

    .plan {
        border-bottom: none;
    }

    .building .container {
        padding-top: 40px;
    }

    .main-title h2 {
        font-size: 36px;
        line-height: 50px;
    }

    .building__strong {
        padding-top: 35px;
    }

    .building__wrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .building__docs-content {
        margin-left: 0;
    }

    .main-form--small .main-form__wrap {
        margin-right: 30px;
    }

    .building__docs-list {
        max-width: 460px;
    }

    .building__docs-item {
        max-width: 195px;
    }

    .building__docs-content {
        padding-top: 70px;
    }

    .building .container {
        padding-bottom: 120px;
    }

    .payment .parallax-wrap {
        display: none;
    }

    .map-block {
        background-image: none !important;
    }

    .map-block .container {
        padding-top: 120px;
    }

    // .footer__info {
    //     flex-wrap: wrap;
    // }

    // .footer__links {
    //     order: 2;
    //     width: 45%;
    //     margin-bottom: 20px;
    //     text-align: right;
    // }

    // .footer__tel-content {
    //     order: 3;
    //     align-items: flex-end;
    //     width: 100%;
    // }

    // .footer__social-content {
    //     width: 45%;
    // }

    .footer__info {
        max-width: 620px;
    }

    .footer__logo-content {
        flex-direction: column;
        margin-right: 40px;
        max-width: 150px;
    }

    .footer__logo {
        margin-bottom: 10px;
    }

    .map-block__info {
        left: 30px;
    }

    .district__content {
        padding-top: 0;
    }

    .district .container {
        padding-top: 60px;
    }

    .fancybox-slide--html .fancybox-close-small {
        right: 5px;
        top: 5px;
        color: #010101;
    }


    .fancybox-button svg {
        width: 38px;
        height: 38px;
    }

    .popup-big {
        max-width: 910px;
    }

    .intro-block__video {
        display: none;
    }
}

@media (max-width: 923px) {
    .header__list {
        display: none;
    }

    .header__logo-content {
        max-width: 167px;
    }

    .header__logo-wrap {
        margin-right: 0;
        width: 167px;
    }

    .intro .container {
        padding-top: 58px;
        padding-bottom: 120px;
    }

    .intro__content {
        margin-left: 10px;
    }

    .intro::before {
        display: none;
    }

    .facilities__list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .facilities__item {
        width: 33%;
        margin-bottom: 30px;
    }

    .facilities__item:nth-child(2) .facilities__image {
        margin-bottom: 0;
    }

    .facilities__item:nth-child(3) .facilities__image {
        margin-bottom: 0;
    }

    .facilities__item:nth-child(5) .facilities__image {
        margin-bottom: 0;
        margin-left: 20px;
    }

    .facilities__item:nth-child(2) {
        margin-top: 0;
    }

    .facilities__item:nth-child(3) {
        margin-top: 0;
    }

    .facilities__item:nth-child(4) {
        margin-top: 0;
    }

    .facilities__item:nth-child(5) {
        margin-top: 0;
    }

    .facilities__image {
        min-height: 180px;
    }

    .facilities__image {
        width: 70%;
        align-items: flex-end;
        margin-bottom: 20px !important;
    }

    .facilities__item:nth-child(3) .facilities__image {
        width: 50%;
    }

    .facilities {
        background-size: contain;
    }

    .facilities__list {
        padding-bottom: 40px;
    }

    .facilities__description-wrap p {
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 24px;
        line-height: 36px;
    }

    .region__list {
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 40px;
    }

    .region__item {
        width: 35%;
        margin-bottom: 20px;
    }

    .region__image {
        min-height: 281px;
    }

    .region__item:nth-child(3) {
        margin-top: 0;
    }

    .region__item:nth-child(3) .region__image {
        margin: 0 auto;
    }

    .region__item:nth-child(3) .region__image img {
        margin-bottom: 20px;
    }

    .region__image {
        width: 90%;
        margin: 0 auto;
    }

    .region__item:nth-child(4) .region__image {
        margin-right: 0;
    }

    .infrastructure__item {
        max-width: 175px;
    }

    .opportunities__item:nth-child(3) .opportunities__image {
        width: 65%;
    }

    .opportunities__image {
        width: 75%;
        margin: 0 auto;
    }

    .opportunities__image img {
        display: block;
        width: 100%;
    }

    .opportunities .container {
        padding-bottom: 265px;
    }

    .materials__item {
        max-width: 235px;
    }

    .building__image {
        width: 100%;
    }

    .building__image img {
        display: block;
        max-width: 100%;
    }

    .main-form--small {
        max-width: 355px;
    }

    .excursion .main-title {
        padding-bottom: 40px;
    }

    .opportunities__item:nth-child(4) .opportunities__num {
        top: 72px;
    }
}

@media (max-width: 823px) {
    .intro .container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .intro__title h1 {
        font-size: 38px;
        line-height: 50px;
    }

    .intro__content {
        margin-left: 0;
        text-align: center;
    }

    .intro__title-description {
        margin-top: 10px;
    }

    .intro__text {
        font-size: 22px;
    }

    .intro__btn-wrap {
        margin-right: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        max-width: 265px;
        width: 100%;
        margin-top: 70px;
    }

    .intro__btn {
        margin-bottom: 0;
    }

    .presentation .container {
        flex-direction: column;
        align-items: center;
    }

    .main-title h2 {
        font-size: 32px;
        line-height: 46px;
    }

    .main-title {
        text-align: center;
    }

    .presentation .container {
        padding-top: 65px;
    }

    .main-form__title-content {
        text-align: center;
    }

    .main-form__title {
        font-size: 26px;
        line-height: 34px;
    }

    .main-form__subtitle {
        font-size: 16px;
    }

    .main-form__wrap {
        padding-left: 15px;
        padding-right: 15px;
    }

    .facilities__item p {
        max-width: 180px;
        margin: 0 auto;
    }

    .district .container {
        flex-direction: column;
        align-items: center;
    }

    .district .list-big {
        margin-left: 0;
    }

    .district .list-big {
        padding-top: 35px;
    }

    .district .list-big {
        max-width: 380px;
        width: 100%;
        margin: 0 auto;
    }

    .district__info {
        margin-left: 0;
        margin-top: 40px;
    }

    .district__info img {
        max-width: 550px;
        margin: 0 auto;
    }

    .district__link {
        left: 190px;
    }

    .selection__form-title {
        font-size: 26px;
    }

    .selection__select-price {
        font-size: 46px;
    }

    .selection__select-price-item .rub {
        font-size: 46px;
    }

    .selection__select-price-item .rub::after {
        display: none;
    }

    .selection__select-price-item .rub::before {
        height: 2px;
        width: 17px;
        bottom: 19px;
        left: 1px;
    }

    .projects__content {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }

    .projects__image {
        margin: 0 auto;
        max-width: 540px;
        margin-bottom: 38px;
    }

    .projects .list-green {
        max-width: 300px;
    }

    .projects .container {
        padding-bottom: 80px;
    }

    .infrastructure__list {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .infrastructure__item {
        width: 35%;
        max-width: none;
        margin-bottom: 40px;
    }

    .infrastructure__info--mobile {
        margin-top: 15px;
    }

    .excursion .container {
        flex-direction: column;
        align-items: center;
    }

    .excursion .list-green {
        display: none;
    }

    .excursion .list-title {
        display: none;
    }

    .excursion {
        background-size: initial;
        background-position: bottom;
    }

    .excursion::after {
        display: none;
    }

    .excursion::before {
        top: 0;
    }

    .excursion .container::before {
        display: none;
    }

    .excursion .main-title {
        padding-bottom: 0;
    }

    .main-title p {
        font-size: 20px;
    }

    .excursion .main-title p {
        line-height: 30px;
        padding-top: 30px;
    }

    .excursion .container {
        padding-bottom: 80px;
    }

    .excursion .container {
        padding-top: 40px;
    }

    .opportunities__item strong {
        max-width: 200px;
        width: 100%;
        margin: 0 auto;
    }

    .opportunities__item p {
        max-width: 200px;
        width: 100%;
        margin: 0 auto;
    }

    .plan__content {
        flex-direction: column;
        align-items: center;
    }

    .plan .main-form {
        margin-top: 120px;
    }

    .plan__book {
        width: 900px;
        left: -50px;
    }

    .building__item {
        margin-right: 15px;
    }

    .building__docs-content {
        flex-direction: column;
        align-items: center;
    }

    .main-form--small .main-form__wrap {
        margin-bottom: 30px;
        margin-right: 0;
    }

    .building__docs-list {
        max-width: 520px;
    }

    .materials__image {
        width: 70%;
    }

    .payment .container {
        flex-direction: column;
        align-items: center;
    }

    .payment__content-info {
        margin: 0 auto;
    }

    .payment__bank-doc {
        display: block;
        width: 300px;
        top: 700px;
    }

    .payment__price-info {
        padding-top: 55px;
    }

    .payment .main-form {
        margin-top: 220px;
    }

    .building .container {
        padding-bottom: 80px;
    }

    .map-block__info {
        padding-left: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 20px;
        max-width: 420px;
    }

    .map-block .container {
        padding-top: 70px;
    }

    .footer__logo-content {
        margin-right: 25px;
    }

    .payment .parallax-wrap {
        display: block;
    }

    .payment .parallax-wrap--index .parallax-item:nth-child(1) {
        display: none;
    }

    .payment .parallax-item:nth-child(1) {
        position: absolute;
        width: 100px;
        top: 656px !important;
        left: 45% !important;
    }

    .payment .parallax-item:nth-child(2) {
        width: 80px;
        position: absolute;
        top: 670px !important;
    }

    .payment .parallax-item:nth-child(3) {
        position: absolute;
        width: 100px;
        top: 705px !important;
        left: 33% !important;
    }

    .opportunities__item:nth-child(5) .opportunities__num {
        right: -10px;
    }

    .plan__list {
        justify-content: space-between;
    }

    .plan__item {
        margin-right: 0;
    }

    .presentation .main-title {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .intro__title-description {
        margin-left: 0;
    }

    .projects__duplex-content {
        top: 430px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .circle-pulse {
        left: 213px;
    }

    .selection__inner.final .main-form__field {
        margin: 0 auto;
        margin-bottom: 12px;
    }

    .selection__inner.final .btn-main {
        display: block;
        margin: 0 auto;
    }

    .selection__inner.final .main-form__description {
        margin: 0 auto;
        margin-top: 25px;
    }

    .toggle-ingo {
        font-size: 46px;
    }
}

@media (max-width: 723px) {
    .header__logo-content span {
        display: none;
    }

    .facilities__title-content {
        display: block;
    }

    .facilities__num-item {
        margin-top: 0;
        margin: 0 auto;
        padding-top: 20px;
    }

    .facilities .main-title {
        text-align: center;
    }

    .facilities__num-item p {
        font-size: 100px;
    }

    .facilities__image {
        min-height: 135px;
    }

    .facilities__description-wrap p {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .region__image {
        min-height: 255px;
    }

    .opportunities__image {
        min-height: 230px;
    }

    .materials__item {
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

    .plan .main-form {
        z-index: 3;
    }

    .building__list {
        flex-direction: column;
        align-items: center;
    }

    .building__item {
        margin-right: 0;
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .map-block__content {
        height: 325px;
    }

    #map {
        height: 325px;
    }

    .map-block__info {
        display: none;
    }

    .map-block__info--mobile {
        position: static;
        display: block;
    }

    .map-block::before {
        display: none;
    }

    .map-block::after {
        display: none;
    }

    .footer__logo-content span {
      display: none;
    }

    .footer .container {
      flex-direction: column;
      align-items: center;
    }

    .footer__logo-content {
      margin-right: 0;
      margin-bottom: 33px;
    }

    .footer__info {
        flex-direction: column-reverse;
        align-items: center;
    }

    .footer__tel-content {
        display: none;
    }

    .footer__social-content {
        margin-bottom: 37px;
    }

    .footer__links {
        text-align: center;
    }

    .map-block .container {
        padding-top: 36px;
    }

    .footer .container {
        padding-top: 53px;
    }

    .map-block .container {
        padding-left: 0;
        padding-right: 0;
    }

    .map-block__info--mobile {
        padding-left: 20px;
        padding-right: 20px;
    }

    .popup-big {
        max-width: 385px;
    }

    .popup-big__bg {
        display: none;
    }

    .intro-thanks .intro__title h2 {
        font-size: 36px;
    }

    .selection__inner.final .main-form__title {
        font-size: 26px;
    }

    .selection__inner.final .main-form__subtitle {
        font-size: 18px;
        padding-top: 7px;
    }

}

@media (max-width: 623px) {
    .intro__title h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .main-title h2 {
        font-size: 30px;
        line-height: 38px;
    }

    .presentation__book {
        left: -140px;
    }

    .presentation .container {
        padding-bottom: 55px;
    }

    .facilities__list {
        flex-direction: column;
        align-items: center;
    }

    .facilities__item {
        max-width: 190px;
        width: 100%;
    }

    .facilities__image {
        width: auto;
        min-height: 0;
    }

    .facilities__item:nth-child(3) .facilities__image {
        width: auto;
    }

    .facilities__image img {
        width: auto;
        max-width: 100%;
    }

    .facilities__item {
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .facilities__list {
        padding-top: 37px;
    }

    .facilities__item:nth-child(5) .facilities__image {
        margin-left: 0;
    }

    .facilities__description-wrap p {
        font-size: 20px;
        line-height: 30px;
    }

    .region__list {
        flex-direction: column;
        align-items: center;
    }

    .region__image {
        width: 90%;
        min-height: 0;
    }

    .region__image img {
        max-width: 100%;
        width: auto;
    }

    .region__item {
        max-width: 250px;
        width: 100%;
        margin-bottom: 45px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .district__link {
        left: 24%;
    }

    .selection__form-title {
        font-size: 24px;
        display: block;
        text-align: center;
    }

    .selection__select-price {
        font-size: 32px;
    }

    .selection__select-price-item .rub {
        font-size: 32px;
    }

    .selection__select-price-item .rub::before {
        width: 13px;
        bottom: 13px;
    }

    .selection__pricer {
        font-size: 16px;
    }

    .selection__price-container .rub::before {
        width: 5px;
        bottom: 6px;
    }

    .selection__panel {
        flex-direction: column;
        align-items: center;
    }

    .selection__prev-btn {
        margin-top: 43px;
    }

    .selection__wrap {
        padding-top: 41px;
        padding-bottom: 41px;
    }

    .selection__form-title {
        margin-bottom: 33px;
    }

    .infrastructure__list {
        flex-direction: column;
        align-items: center;
    }

    .infrastructure__item {
        width: 100%;
        max-width: 205px;
        margin-bottom: 60px;
    }

    .infrastructure__item:nth-child(3) .infrastructure__image {
        margin-bottom: 30px;
    }

    .infrastructure__item:nth-child(4) .infrastructure__image {
        margin-bottom: 10px;
    }

    .infrastructure__image {
        min-height: 0;
    }

    .infrastructure__image img {
        width: auto;
    }

    .infrastructure__info--mobile {
        max-width: 280px;
    }

    .opportunities__list {
        flex-direction: column;
        align-items: center;
    }

    .opportunities__item {
        max-width: 215px;
        width: 100%;
    }

    .opportunities__item strong {
        max-width: none;
    }

    .opportunities__item p {
        max-width: none;
    }

    .opportunities__image {
        min-height: 0;
        width: auto;
    }

    .opportunities__image img {
        width: auto;
    }

    .opportunities__item:nth-child(1) .opportunities__num {
        top: 24px;
        right: -64px;
    }

    .opportunities__item:nth-child(2) .opportunities__num {
        top: 100px;
        right: -25px;
    }

    .opportunities__item:nth-child(3) .opportunities__num {
        top: 22px;
        right: 0px;
    }

    .opportunities__item:nth-child(4) .opportunities__num {
        top: 0;
        right: -19px;
    }

    .opportunities__item:nth-child(5) .opportunities__num {
        right: 0;
        top: 30px;
    }

    .opportunities__item {
        margin-bottom: 45px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .opportunities::before {
        bottom: -50px;
        width: 100%;
        height: 155px;
        left: 0;
    }

    .opportunities__list {
        padding-top: 42px;
    }

    .opportunities .container {
        padding-bottom: 190px;
    }

    .materials__list {
        flex-direction: column;
        align-items: center;
    }

    .materials__item {
        margin-right: 0;
        margin-bottom: 80px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .materials__image {
        min-height: 0;
        width: 90%;
    }

    .main-title p {
        font-size: 18px;
        line-height: 24px !important;
    }

    .materials__list {
        padding-top: 40px;
    }

    .materials .parallax-item:nth-child(1) {
        top: 190px !important;
        left: 54% !important;
        width: 50px;
    }

    .materials .parallax-item:nth-child(1) img {
        display: block;
        width: 100%;
    }

    .materials .parallax-item:nth-child(2) {
        width: 60px;
        top: 522px !important;
    }

    .materials .parallax-item:nth-child(2) img {
        display: block;
        width: 100%;
    }

    .materials .parallax-item:nth-child(3) {
        top: 896px !important;
        right: 51% !important;
        width: 55px;
    }

    .materials .parallax-item:nth-child(3) img {
        display: block;
        width: 100%;
    }

    .materials .parallax-item:nth-child(4) {
        top: 830px !important;
        right: 49% !important;
    }

    .plan__book {
        width: 750px;
    }

    .plan .parallax-item:nth-child(2) {
        top: 123px !important;
        left: -10px !important;
    }

    .plan__list {
        flex-direction: column;
    }

    .plan__item br {
        display: none;
    }

    .plan__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .plan__item p {
        padding-top: 4px;
        margin-left: 16px;
    }

    .building__list {
        padding-top: 35px;
    }

    .main-form__subtitle {
        font-size: 18px;
    }

    .building__docs-list {
        flex-direction: column;
        align-items: center;
    }

    .building__docs-item {
        margin-bottom: 55px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .building .container {
        padding-bottom: 65px;
    }

    .payment__bank-doc {
        left: 50%;
        transform: translate(-50%, 0);
    }

    .payment .parallax-item:nth-child(3) {
        left: 16% !important;
    }

    .payment .parallax-item:nth-child(1) {
        left: 34% !important;
    }

    .payment .parallax-item:nth-child(2) {
        left: 59% !important;
    }

    .map-block__info h4 {
        font-size: 26px;
        text-align: center;
    }

    .map-block__description {
        text-align: center;
    }

    .map-block__contacts {
        flex-direction: column;
        align-items: center;
    }

    .map-block__contacts-item {
        text-align: center;
        &:nth-child(1) {
            order: 1;
        }

        &:nth-child(2) {
            order: 3;
        }

        &:nth-child(3) {
            order: 2;
        }

        &:nth-child(4) {
            order: 4;
        }
    }

    .map-block__info--mobile {
        padding-bottom: 17px;
    }

    .intro__text {
        line-height: 30px;
        padding-bottom: 42px;
        padding-top: 20px;
    }

    .toggle-ingo {
        font-size: 32px;
    }
}

@media (max-width: 523px) {
    .intro__title h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .intro__italic {
        display: block;
    }

    .intro__title-description {
        margin-top: 15px;
    }

    .header__tel--mobile {
        display: block;
        margin-top: 15px;
    }

    .header__tel-content {
        display: none;
    }

    .main-title h2 {
        font-size: 26px;
        line-height: 34px;
    }

    .presentation .container {
        padding-top: 57px;
    }

    .presentation__book {
        display: none;
    }

    .facilities__description-wrap p {
        padding-left: 30px;
        padding-right: 30px;
    }

    .district__info img {
        width: 100%;
        max-width: none;
    }

    .district__info {
        max-width: 550px;
        margin: 0 auto;
        margin-top: 40px;
        width: 100%;
    }

    .plan__book {
        top: 30px;
    }

    .plan__list {
        padding-top: 39px;
    }

    .payment__bank-doc {
        top: 670px;
    }

    .payment .parallax-item:nth-child(1) {
        top: 620px !important;
    }

    .payment .parallax-item:nth-child(3) {
        left: 11% !important;
        top: 663px !important;
    }

    .selection__item {
        padding-left: 15px;
        padding-right: 15px;
    }

    .materials .main-title h2 {
        max-width: 280px;
        margin: 0 auto;
    }

    .materials .main-title p {
        max-width: 280px;
        margin: 0 auto;
    }

    .materials .main-title h2 span {
        display: block;
    }

    .materials .parallax-item:nth-child(1) {
        top: 268px !important;
    }

    .materials .parallax-item:nth-child(2) {
        top: 610px !important;
    }

    .materials .parallax-item:nth-child(3) {
        top: 985px !important;
    }

    .materials .parallax-item:nth-child(4) {
        top: 913px !important;
    }

    .plan .parallax-item:nth-child(1) {
        left: 0px !important;
    }

    .payment .parallax-item:nth-child(2) {
        left: 69% !important;
    }

    .main-form__wrap {
        padding-left: 0;
        padding-right: 0;
        border: none;
    }

    .main-form__subtitle {
        font-size: 16px;
    }

    .selection__item {
        padding-left: 0;
        padding-right: 0;
    }

    .selection__wrap {
        border: none;
    }

    .selection__description-list {
        margin-right: -44px;
    }

    .projects__duplex-content {
        display: none;
    }

    .list-green li {
        padding-left: 43px;
    }

    .map-block__info--mobile {
        padding-left: 0;
        padding-right: 0;
    }

    .district .container {
        padding-left: 0;
        padding-right: 0;
    }

    .district__content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .plan .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .plan .main-title {
        padding-left: 10px;
        padding-right: 10px;
    }

    .excursion .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .excursion__content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .intro-thanks .intro__title h2 {
        font-size: 32px;
    }

    .question-2 .selection__questions span {
        color: #828b9a;
    }

    .selection__inner.final .btn-main i {
      display: none;
    }
}

@media (max-width: 374px) {
    .payment__bank-doc {
        width: 270px;
    }

    .payment .parallax-item:nth-child(3) {
        left: 4% !important;
    }

    .payment .parallax-item:nth-child(2) {
        left: 72% !important;
    }
}

@media (max-width: 349px) {
    .btn-main {
        font-size: 13px;
    }
}